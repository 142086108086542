const { range } = require("lodash");
import Axios from "axios";
import "flatpickr";
global.rangePlugin = require("flatpickr/dist/plugins/rangePlugin");
import "flatpickr/dist/flatpickr.min.css";

flatpickr("#eventDate", {
  enableTime: true,
  minDate: "today",
  locale: 'pt',
  dateFormat: "d/m/Y H:i",
  altFormat: "d/m/Y H:i",
  time_24hr: true,
  disableMobile: true,
});

flatpickr("#startDate", {
  enableTime: true,
  time_24hr: true,
  altInput: true,
  locale: 'pt',
  dateFormat: "Y-m-d H:i",
  altFormat: "d/m/Y H:i",
  disableMobile: true,
  plugins: [new rangePlugin({ input: "#endDate" })],
});

flatpickr("#inputDate", {
  enableTime: false,
  altInput: true,
  locale: 'pt',
  minDate: "today",
  dateFormat: "Y-m-d",
  altFormat: "d/m/Y",
  // time_24hr: true,
  disableMobile: true,
});

flatpickr("#rangeDate", {
  enableTime: false,
  altInput: true,
  wrap: true,
  locale: 'pt',
  mode: "range",
  dateFormat: "Y-m-d",
  altFormat: "d/m/Y",
  disableMobile: true,
});

flatpickr("#inputTime", {
  enableTime: true,
  noCalendar: true,
  wrap: true,
  locale: 'pt',
  dateFormat: "H:i",
  time_24hr: true,
  disableMobile: true,
});

flatpickr("#inputDateTime", {
  enableTime: true,
  altInput: true,
  locale: 'pt',
  dateFormat: "Y-m-d H:i",
  altFormat: "d/m/Y H:i",
  time_24hr: true,
  disableMobile: true,
});

tinymce.init({
  selector: "textarea#full_textarea",
  language: "pt_BR",
  plugins: "advlist lists",
  toolbar:
    "undo redo bold italic strikethrough | numlist bullist | alignleft aligncenter alignright alignjustify",
  height: "400",
});

//  tinymce.init({
//   menubar: false,
//   language: 'pt_BR',
//   selector: 'textarea#simple_textarea',
//   plugins: "link",
//   toolbar: 'undo redo styleselect bold underline italic link | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',  /* enable title field in the Image dialog*/
//   height: "380",
// }); 

tinymce.init({
  selector: "textarea#simple_textarea",
  language: 'pt_BR',
  convert_urls: true,
  relative_urls: false,
  remove_script_host: false,
  paste_data_images: true,
  image_title: true,
  automatic_uploads: true,
  images_upload_url: "/api/upload",
  file_picker_types: "image",
  height: "420",
  plugins: [
    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
    "searchreplace wordcount visualblocks visualchars code fullscreen",
    "insertdatetime media nonbreaking save table contextmenu directionality",
    "template paste textcolor colorpicker textpattern"
  ],
  toolbar1:
    "insertfile undo redo | styleselect | link image |bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
  toolbar2: "print preview media | forecolor backcolor",
  // override default upload handler to simulate successful upload
  file_picker_callback: function (cb, value, meta) {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = function () {
      var file = this.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        var id = "blobid" + new Date().getTime();
        var blobCache = tinymce.activeEditor.editorUpload.blobCache;
        var base64 = reader.result.split(",")[1];
        var blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), { title: file.name });
      };
    };
    input.click();
  },
  setup(editor) {
    editor.on("keydown", function (e) {
      if ((e.keyCode == 8 || e.keyCode == 46) && tinymce.activeEditor.selection) {
        var selectedNode = tinymce.activeEditor.selection.getNode();
        if (selectedNode && selectedNode.nodeName == 'IMG') {
          var imageSrc = selectedNode.src;
          Axios.post("/api/remove_media", { image: imageSrc })
            .then(res => {
              console.log(res)
            })
            .catch(err => {
              console.error(err);
            })
          //here you can call your server to delete the image
        }
      }
    });
  }
});
